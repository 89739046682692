import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Carousel } from "react-responsive-carousel"

import { Button } from "../../core/Button"
import { Section, SectionWrapper, Title, Text } from "../../core/commonExports"
import { OnDesktop, OnMobile } from "../../DesktopMobileComps"
import {ReviewsContainer, ReviewCard, ReviewHeader, ReviewMetaInfo, ReviewerProfilePicture, ReviewText} from "./ReviewSectionStyles"

export const reviewData = [
  {
    reviewer: "Mahavir Furniture",
    reviewerDP: <StaticImage 
      src="../../../assets/images/campaigns/review-imgs/mahavir.webp"
      alt="mahavir-furniture"
      loading="lazy"
    />,
    businessImg: <StaticImage 
      src="../../../assets/images/campaigns/review-imgs/mahavir-furniture.webp"
      alt="mahavir-furniture"
      loading="lazy"
    />,
    business: "Furniture Shop in Mumbai",
    info: "8 months ago | 3 Reviews",
    reviewText: "One of the best business insurance companies that I’ve come across..seamless and hassle free procedure to get business insurance which takes care of fire, theft, floods, storm earthquake etc. The procedure was so fast and easy. I have actually waited for many years for someone to come up and sell me this product..I would highly recommend each and every business owner to get Insurace from team Verak.",
  },
  {
    reviewer: "Anuj Gupta",
    reviewerDP: <StaticImage 
      src="../../../assets/images/campaigns/review-imgs/anuj-gupta.webp"
      alt="anuj-gupta"
      loading="lazy"
    />,
    businessImg: <StaticImage 
      src="../../../assets/images/campaigns/review-imgs/anuj-gupta-auto-parts.webp"
      alt="mahavir-furniture"
      loading="lazy"
    />,
    business: "Automobile parts trader, Faridabad",
    info: "2 Months ago | 98 Reviews",
    reviewText: "I was looking to insure my business for a long time but was confused about the procedure and coverage. Then this Verak team approached me. Even though the entire purchase was done online, I found the process very transparent and the staff very supportive. I ended up buying it at an affordable price and that too with extra benefits. It was totally worth it!",
  },
  {
    reviewer: "Lucky Lipi",
    reviewerDP: <StaticImage 
      src="../../../assets/images/campaigns/review-imgs/lucky-lipi.webp"
      alt="lucky-lipi"
      loading="lazy"
    />,
    businessImg: <StaticImage 
      src="../../../assets/images/campaigns/review-imgs/lucky-lipi-grocery-store.webp"
      alt="mahavir-furniture"
      loading="lazy"
    />,
    business: "Grocery store owner in Odisha",
    info: "3 months ago | 9 Reviews",
    reviewText: "Mera grocery store highway par ata hai toh humesha chinta bani rehti thi ki kuch anhoni na ho jaye.Mene Verak ka ad Facebook par dekha, aur phir unke insurance expert ne mujhe vyapar suraksha policy lene mein madad ki. Premium kafi kam tha aur process bhi online tha toh pura process kuch hi dino mein ho gaya.Very happy with the service. Thanks you Verak",
  },
]

export const ReviewItem = ({reviewData = {}}) => {
  const {info, reviewer, reviewerDP, businessImg, reviewText, business} = reviewData

  const fiveStars = (
    <div style={{width: "78px", height: "14px"}}>
      <StaticImage 
        src="../../../assets/images/campaigns/review-imgs/google-review-5-stars.webp"
        alt="5-stars"
        loading="lazy"
        placeholder="blurred"
      />
    </div>
  )

  const reviewMetaInfo = (
    <ReviewMetaInfo>
      <ReviewerProfilePicture>
        {reviewerDP}
      </ReviewerProfilePicture>
      <section>
        <section style={{display: "flex", gap: "1rem"}}>
          <Text fontSize="16px" mfontSize="13px" lineHeight="17px" mlineHeight="15px" fontWeight="500" color="rgba(0, 0, 0, 0.87)" style={{margin: "0", marginRight: "0.25rem"}}>{reviewer}</Text>
          <OnDesktop style={{width: "auto"}}>{fiveStars}</OnDesktop>
        </section>
        <OnMobile>
          <Text fontSize="14px" mfontSize="12px" lineHeight="24px" mlineHeight="15px" color="#000" fontWeight="600" desktopStyles={{margin: '0'}} mobileStyles={{marginTop: "2px", marginBottom: "6px"}} style={{ textAlign: "left", fontStyle: "italic"}}>
            {business}
          </Text>
        </OnMobile>
        <Text fontSize="14px" mfontSize="12px" lineHeight="19px" mlineHeight="15px" color="rgba(0, 0, 0, 0.54)" fontWeight="400" style={{ margin: "0", display: "flex", textAlign: "left"}}>
          {info}
        </Text>
        <OnDesktop>
          <Text fontSize="14px" mfontSize="12px" lineHeight="19px" mlineHeight="15px" color="#2A363D" fontWeight="600" desktopStyles={{margin: '6px 0'}} style={{ textAlign: "left"}}>
            {business}
          </Text>
        </OnDesktop>
      </section>
    </ReviewMetaInfo>
  )

  const googleLogo = (
    <div style={{width: "24px", height: "24px"}}>
      <StaticImage 
        src="../../../assets/images/campaigns/review-imgs/google.webp"
        alt="google-review"
        loading="lazy"
        placeholder="blurred"
      />
    </div>
  )

  const reviewTextContainer = (
    <ReviewText>
      {reviewText}
    </ReviewText>
  )

  return (
    <ReviewCard>
      <OnMobile>
        <div style={{width: "min(260px, 100%)", height: "142px"}}>
          {businessImg}
        </div>
        <div className="d-flex" style={{gap: "12px", alignItems: "center"}}>
          {googleLogo}
          {fiveStars}
        </div>
        {reviewTextContainer}
      </OnMobile>
      <ReviewHeader>
        {reviewMetaInfo}
        <OnDesktop style={{width: "auto"}}>{googleLogo}</OnDesktop>
      </ReviewHeader>
      <OnDesktop>
        {reviewTextContainer}
      </OnDesktop>
    </ReviewCard>
  )
}

const ReviewsSection = ({
  handleGetQuoteClick, 
  quoteCtaProps={},
  style={},
  desktopStyles={background: "#fff"},
  mobileStyles={background: "#F6F9FF"},
  shopInsurance=false
}) => {
  const {label, uppercaseLabel} = quoteCtaProps
  return (
    <Section desktopStyles={desktopStyles} mobileStyles={mobileStyles} style={style}>
      <SectionWrapper style={{padding: "2rem 0px"}}>
        <Title fontSize="32px" mfontSize="20px" lineHeight="38px" mlineHeight="32px" fontWeight="700" color="#333333" style={{textAlign: "center", marginBottom: "48px"}}>
          Join 5000+ {shopInsurance ? "shop owners" : "businesses"} that trust us to protect them!
        </Title>
        <ReviewsContainer>
          <Carousel centerMode infiniteLoop>
            {reviewData.map(review => <ReviewItem reviewData={review} />)}
          </Carousel>
        </ReviewsContainer>
        <Button label={label || "VIEW PRICES"} uppercaseLabel={uppercaseLabel || false} type="flatdesign" wide style={{margin: 'auto', marginTop: '2rem'}} onClick={handleGetQuoteClick} />
      </SectionWrapper>
    </Section>
  );
}

export default ReviewsSection