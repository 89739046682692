import styled from "styled-components"

export const ReviewsContainer = styled.section`
  width: 100%;
  margin: auto;
  .carousel, .carousel-root {
    width: 100%;
  }
  .slide {
    min-width: 78% !important;
  }
  .slider-wrapper {
    padding: 2rem 5rem;
  }
  .carousel .slider-wrapper.axis-horizontal .slider {
    display: flex;
    gap: 2rem;
    align-items: flex-start;
  }
  .carousel.carousel-slider .control-dots .dot {
    background: #244BCE !important;
  }
  .carousel-status {
    display: none;
  }
  @media screen and (max-width: 768px) {
    .slide {
      min-width: 74% !important;
    }
    .slider-wrapper {
      padding: 2rem 1rem;
    }
  }
`

export const ReviewCard = styled.article`
  padding: 1.5rem;
  margin: auto;
  margin-bottom: 1rem;
  width: min(780px, 100%);
  min-height: 310px;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
  border-radius: 12px;

  @media screen and (max-width: 768px) {
    padding: 1rem;
    border-radius: 8px;
  }
`

export const ReviewHeader = styled.section`
  display: flex;
  justify-content: space-between;
`

export const ReviewMetaInfo = styled(ReviewHeader)`
  gap: 0.83rem;

  .d-flex {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  @media screen and (max-width: 768px) {
    gap: 0.625rem;
    .d-flex {
      margin-bottom: 0.25rem;
    }
  }
`

export const ReviewerProfilePicture = styled.section`
  width: 40px;
`

export const ReviewText = styled.section`
  margin-left: 54px;
  margin-right: 27px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #2A363D;
  text-align: left;

  @media screen and (max-width: 768px){
    margin: 12px 0;
    font-size: 14px;
  }
`